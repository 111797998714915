import { NavLink as Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import "./Sidebar.css"


const Sidebar = () => {
    const [isActive, setIsActive] = useState("work");
    const [isHighlight, setIsHighlight] = useState('');

    const [data, setData] = useState([]);
    useEffect(() => {
        fetch("https://alina.bytebro.de/server/api/getPosts.php")
            .then(res => res.json())
            .then(data => {
                setData(data);
            }).catch(err => {
                console.log(err);
            });
    }, []);

    const handleClick = (e) => {
        const id = e.target.id;
        if (id) {
            setIsActive(id);
        } else {
            setIsActive("work");
        }
    }

    const handleHighlight = (e) => {
        const id = e.target.id;
        if (id) {
            setIsHighlight(id);

        } else {
            setIsHighlight(null);
        }
    }

    return (
        <div className="sidebar">
            <Link to="/" className="main" onClick={handleClick}>Alina Inserra</Link>
            <div className="sidebar-top">
                {data.map((item, index) => {
                    if (item.highlight) {
                        return (
                            <a href={"/#" + item.id} id={item.highlight} key={index} onClick={handleHighlight} className={isHighlight == item.highlight ? 'active' : ''}>{item.highlight}</a>
                        )
                    } else {
                        return null;
                    }
                })}
            </div>
            <div className="sidebar-main">
                <Link to="/" className="main2" onClick={handleClick}>Alina Inserra</Link>
                <Link to="/" id="work" onClick={handleClick}>{isActive === "work" ? "                  Work" : "Work"}</Link>
                <Link to="/about" id="about" onClick={handleClick}>{isActive === "about" ? "                  Info" : "Info"}</Link>
                <Link to="/CV" id="cv" onClick={handleClick}>{isActive === "cv" ? "                   Vita" : "Vita"}</Link>
            </div>
            <div className="sidebar-bottom">
                <Link to="/datenschutz" id="datenschutz" onClick={handleClick}>{isActive === "datenschutz" ? "               Datenschutz" : "Datenschutz"}</Link>
                <Link to="/Impressum" id="impressum" onClick={handleClick}>{isActive === "impressum" ? "               Impressum" : "Impressum"}</Link>
                <p>&copy;Alina Inserra</p>
            </div>
        </div>
    )
}

export default Sidebar
